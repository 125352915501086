// Modular standard library for JavaScript ( polyfills )
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Metronic
import './metronic.js';
import './css/application_metronic.scss';

import {
  custom_popover,
} from 'form_helpers';

import '@hotwired/turbo-rails';
import '@rails/actioncable';
import 'controllers';

// Fonts
import '@fortawesome/fontawesome-free/css/all.min.css';

window.Turbo.session.drive = false;

$( document ).on( 'click', '.copy_to_clipboard', function(){
  const $this = $( this );
  const range = document.createRange();
  range.selectNode( $this.prev().get( 0 ) );
  window.getSelection().removeAllRanges();
  window.getSelection().addRange( range );

  try {
    document.execCommand( 'copy' );
    window.toastr.success( 'Copied to clipboard!' );
  } catch( err ) {
    window.toastr.error( 'Oops, copy failed' );
  }

  window.getSelection().removeAllRanges();

  return false;
} );

$( function(){
  custom_popover( '.items-popover' );

  // Metronic 8.x components
  $( '.menu-item.menu-accordion .menu-link' ).on( 'click', function(){
    $( this ).parent().toggleClass( 'show' );
    $( this ).parent().find( '.menu-sub.menu-sub-accordion' ).toggleClass( 'show' );
  } );
} );

/* flash/toastr updates */
function flash_update(){
  let txt = $( 'turbo-frame#flash script' ).text(),
      list;

  if( txt == '' ){ return; }

  list = JSON.parse( txt );

  for( var i = 0; i < list.length; i++ ){
    window.toastr[ list[ i ][ 0 ] ]( list[ i ][ 1 ] );
  }
}
$( function(){
  flash_update();

  let flash = $( 'turbo-frame#flash' )[ 0 ];

  if( flash == undefined ){ return; }

  new MutationObserver( flash_update ).observe( flash, { childList: true } ); }
);
